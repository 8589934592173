import { Member } from '../../../models/member'

import { request } from '..'

export const getMemberList = async (
  params?: { [draftKey: string]: string }
): Promise<Member[]> => {
  const res = await request({
    url: 'members',
    method: 'GET',
    params
  }).catch(err => {
    throw err
  })

  return res.contents
}
