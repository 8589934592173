import { Science } from '../../../models/science'

import { request } from '..'

export const getScienceList = async (
  params?: { [draftKey: string]: string }
): Promise<Science[]> => {
  const res = await request({
    url: 'science',
    method: 'GET',
    params
  }).catch(err => {
    throw err
  })

  return res.contents
}
