import { Company } from '../../../models/company'

import { request } from '..'

export const getCompany = async (
  params?: { [draftKey: string]: string }
): Promise<Company> => {
  const res = await request({
    url: 'company',
    method: 'GET',
    params
  }).catch(err => {
    throw err
  })

  return res
}
