import { History } from '../../../models/history'

import { request } from '..'

export const getHistory = async (
  params?: { [draftKey: string]: string }
): Promise<History[]> => {
  const res = await request({
    url: 'history',
    method: 'GET',
    params: { ...params, limit: 99 }
  }).catch(err => {
    throw err
  })

  return res.contents
}
