import { News } from '../../../models/news'

import { request } from '..'

export const getNewsList = async (
  params?: { [key: string]: string | number }
): Promise<News[]> => {
  const res = await request({
    url: 'news',
    method: 'GET',
    params
  }).catch(err => {
    throw err
  })

  return res.contents
}
